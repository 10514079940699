<template>
   <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
         <div class="container-fluid">
            <div class="row mb-2">
               <div class="col-sm-6">
                  <h1 class="m-0">Usuários</h1>
               </div><!-- /.col -->
               <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                     <li class="breadcrumb-item"><a href="#">Home</a></li>
                     <li class="breadcrumb-item active">Dashboard</li>
                     <li class="breadcrumb-item active">Usuários</li>
                     <li class="breadcrumb-item active">Criar Usuário</li>
                  </ol>
               </div><!-- /.col -->
            </div><!-- /.row -->
         </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
         <div class="row">
            <div class="col-md-12">
               <div class="card card-outline card-info">
                  <div class="card-header">
                     <h3 class="card-title">
                        Cadastrar Usuário
                     </h3>
                  </div>
                  <!-- /.card-header -->

                  <div class="card-body">
                     <div class="row">
                        <div class="col-6">
                           <div class="form-group mb-4">
                              <label for="exampleInputEmail1">Nome</label>
                              <input type="text" class="form-control" placeholder="Nome">
                           </div>
                        </div>
                        <div class="col-6">
                           <div class="form-group mb-4">
                              <label for="exampleInputEmail1">E-mail</label>
                              <input type="text" class="form-control" placeholder="E-mail">
                           </div>
                        </div>

                        <div class="col-5">
                           <div class="form-group">
                              <label>Perfil</label>
                              <select class="form-control">
                                 <option>Adminstrador</option>
                                 <option>Colaborador</option>
                                 <option>Parceiro</option>
                              </select>
                           </div>
                        </div>

                     </div>
                     <!-- ./row -->

                  </div>
                  <div class="card-footer">
                     <div class="row justify-content-end">
                        <button class="btn btn-success btn-sm text-bold">Salvar</button>
                     </div>
                  </div>
               </div>
            </div>
            <!-- /.col-->
         </div>
         <!-- ./row -->
      </section>
   </div>
</template>

<script>
import QuillVueEditor from "../../article/component/QuillVueEditor.vue";

export default {
   name: "UsersForm",
   components: {QuillVueEditor}
}
</script>


